import React from "react"

import Layout from "../components/layout"
import ButtonLink from "../components/buttonLink";
import { Col, Container, Row } from "react-bootstrap";

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Row>
        <Col>
          <h1>Pagina non trovata</h1>
          <p>La pagina che stai cercando non esiste o non è ancora disponibile.</p>
          <p className="text-center"><ButtonLink href="/" label="Vai alla home" title="federicozanetti.it" /></p></Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
